<template>
  <NamiLoading v-if="isServer" />
  <NamiLoginPrompt v-else-if="showLoginPrompt" dismiss-on-route-change />
  <slot v-else></slot>
</template>

<script lang="ts" setup>
const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const isServer = ref(true);

const showLoginPrompt = ref(false);

onMounted(async () => {
  isServer.value = false;

  await authStore?.waitUntilRefreshIsComplete();

  showLoginPrompt.value = !authStore?.user;
});
</script>

<style scoped></style>
